import { DefaultTheme, Provider as PaperProvider, configureFonts } from 'react-native-paper';


const fontConfig = {
  web: {
    regular: {
      fontSize: 16,
      fontFamily: "Montserrat_400Regular",
      fontWeight: "normal",
    },
    medium: {
      fontSize: 16,
      fontFamily: "Montserrat_400Regular",
      fontWeight: "normal",
    },
    light: {
      fontSize: 16,
      fontFamily: "Montserrat_300Light",
      fontWeight: "normal",
    },
    thin: {
      fontSize: 16,
      fontFamily: "Montserrat_400Regular",
      fontWeight: "normal",
    },
  },
  ios: {
    regular: {
      fontSize: 16,
      fontFamily: "Montserrat_400Regular",
      fontWeight: "normal",
    },
    medium: {
      fontSize: 16,
      fontFamily: "Montserrat_400Regular",
      fontWeight: "normal",
    },
    light: {
      fontSize: 16,
      fontFamily: "Montserrat_300Light",
      fontWeight: "100",
    },
    thin: {
      fontSize: 16,
      fontFamily: "Montserrat_400Regular",
      fontWeight: "normal",
    },
  },
  android: {
    regular: {
      fontSize: 16,
      fontFamily: "Montserrat_400Regular",
    },
    medium: {
      fontSize: 16,
      fontFamily: "Montserrat_400Regular",
      fontWeight: "normal",
    },
    light: {
      fontSize: 16,
      fontFamily: "Montserrat_300Light",
      fontWeight: "normal",
    },
    thin: {
      fontSize: 16,
      fontFamily: "Montserrat_400Regular",
      fontWeight: "normal",
    },
  },
};



export const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#7F4B2A',
    accent: 'yellow',
  },
  roundness: 0,
  fonts: configureFonts(fontConfig),
};