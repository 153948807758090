import { Drawer, IconButton, useTheme } from "react-native-paper";
import { DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer";
import {
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  Text
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// import Animated from "react-native-reanimated";
import React from "react";

export const DrawerContent = (props) => {
  const { navigation } = props;
  const theme = useTheme();

  return (
    <DrawerContentScrollView {...props}>
      <View
        //@ts-ignore
        style={[
          styles.drawerContent,
          {
            backgroundColor: theme.colors.surface,
          },
        ]}
      >
        <View
          style={{
            ...styles.drawerHeader,
            justifyContent: "space-around",
          }}
        >
          <TouchableOpacity
            style={{
              marginLeft: 10,
              justifyContent: "flex-end",
              flexDirection: "row",
            }}
            onPress={() => {
              navigation.toggleDrawer();
            }}
          >
            <IconButton
              icon="close"
              onPress={() => navigation.toggleDrawer()}
            />
          </TouchableOpacity>
        </View>

        <Drawer.Section style={{ marginBottom: 0 }}>
            <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons name="cloud" color={color} size={size} />
            )}
            label={"DD"}
            labelStyle={{
              fontSize: theme.fonts.regular.fontSize,
            }}
            onPress={() => {
              
            }}
          />
        </Drawer.Section>
      </View>
    </DrawerContentScrollView>
  );
};

const styles = StyleSheet.create({
  drawerContent: {
    flex: 1,
  },
  drawerHeader: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 20,
    width: "100%",
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
  },
});
