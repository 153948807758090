import { useTheme } from "@react-navigation/native";
import React from "react";
import { Image, Text, TouchableOpacity, View, ScrollView } from "react-native";
import { Subheading, Title, IconButton, Button } from "react-native-paper";
import { openURL } from "./shared/url-opener.js";
import { FontAwesome } from "@expo/vector-icons";

const Home = ({ navigation }) => {
  const avatarWidth = 150;
  const theme = useTheme();

  const goTo = (social) => {
    switch (social) {
      case "linkedin":
        openURL("https://www.linkedin.com/in/david-fabreguette-8740b454/");
        break;
      case "twitter":
        openURL("https://twitter.com/davonrails");
        break;
      case "contact":
        openURL("mailto:david@fabreguette.fr");
        break;
      case "medium":
        openURL("https://medium.com/@davfabr");
        break;
    }
  };
  return (
    <View style={{ width: "100%", height: "100vh" }}>
      <ScrollView
        style={{
          position: "relative",
          zIndex: 1,
        }}
        contentContainerStyle={{
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <Image
            resizeMode="contain"
            source={require("../assets/profile-photo.png")}
            style={{
              width: avatarWidth,
              height: avatarWidth * 1.5,
              marginBottom: 20,
            }}
          ></Image>
          <Title style={{ lineHeight: 13 }}>David FABREGUETTE</Title>
          <Subheading style={{ marginTop: 10, textAlign: "center" }}>
            Développeur, Geek, Musicien, Lead dev chez 
            <TouchableOpacity
              style={{ marginLeft: 5, textDecoration: "underline" }}
              onPress={() => openURL("https://www.kolsquare.com")}
            > 
              Kolsquare
            </TouchableOpacity>
            &nbsp;et fondateur de
            <TouchableOpacity
              style={{ marginLeft: 5, textDecoration: "underline" }}
              onPress={() => openURL("https://www.lafabfactory.fr")}
            >
              La Fab Factory
            </TouchableOpacity>
          </Subheading>
          
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <IconButton
              icon="linkedin"
              onPress={() => goTo("linkedin")}
            ></IconButton>
            <IconButton
              icon="twitter"
              onPress={() => goTo("twitter")}
            ></IconButton>
            <IconButton
              icon="card-account-details-star"
              onPress={() => openURL("https://www.lafabfactory.fr/cv.pdf")}
            ></IconButton>
            <IconButton
              icon="android-messages"
              onPress={() => goTo("contact")}
            ></IconButton>
            <IconButton
              icon={() => <FontAwesome name="medium" size={24} color="black" />}
              onPress={() => goTo("medium")}
            ></IconButton>
          </View>

          {/* <TouchableOpacity
            onPress={() =>
              openURL("https://www.malt.fr/profile/davidfabreguette")
            }
            style={{
              marginTop: 30,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Title
              style={{
                backgroundColor: "#f7f7f7",
                color: "#333333",
                padding: 10,
                transform: "rotate(3deg)",
              }}
            >
              RETROUVEZ-MOI SUR
            </Title>
            <Image
              resizeMode="contain"
              style={{ width: 200, height: 80 }}
              source={require("../assets/malt-red.svg")}
            />
          </TouchableOpacity> */}
        </View>
      </ScrollView>
      <Image
        source={require("../assets/back.jpg")}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 0,
        }}
      ></Image>
    </View>
  );
};

export default Home;
