import { Platform, Linking } from "react-native";

const linkingOpenUrl = async (url) => {
  try {
    await Linking.openURL(url);
    return true;
  } catch (e) {
    console.warn(e);
    return false;
  }
};

export const openURL = async (url, schemaFallbackUrl = null) => {
  console.log("lllll");
  if (Platform.OS == "web") {
    window.open(schemaFallbackUrl || url);
  }

  // Open the custom settings if the app has one
  else {
    const urlLinkingWorked = await linkingOpenUrl(url);
    console.log("oups", urlLinkingWorked);
    if (!urlLinkingWorked && !!schemaFallbackUrl) {
      await linkingOpenUrl(schemaFallbackUrl);
    }
  }
};
