import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  ScrollView,
} from "react-native";
import { Provider as PaperProvider } from "react-native-paper";
import { NavigationContainer } from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import Home from "./components/home.jsx";
import CV from "./components/cv.jsx";
import { theme } from "./theme";
import { DrawerContent } from "./components/drawer-content.jsx";
import { AppBar } from "./components/app-bar";
import { createStackNavigator } from "@react-navigation/stack";
import {
  useFonts,
  Montserrat_300Light,
  Montserrat_400Regular,
} from "@expo-google-fonts/montserrat";

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const linking = {
  prefixes: ["https://app.example.com"],
  config: {
    screens: {
      Home: "",
      CV: "/cv"
    },
  },
};

export default function App() {
  let [fontsLoaded] = useFonts({
    Montserrat_300Light,
    Montserrat_400Regular,
  });

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "#cbc8bc",
        width: "100%",
        height: "100vh",
      }}
    >
      {!fontsLoaded ? (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <Text>...</Text>
        </View>
      ) : (
        <PaperProvider theme={theme}>
          <NavigationContainer linking={linking}>
            <Drawer.Navigator
              screenOptions={{
                header: (props) => <AppBar {...props}></AppBar>,
              }}
              drawerContent={(props) => (
                <DrawerContent {...props}></DrawerContent>
              )}
            >
              <Drawer.Screen
                name="Home"
                component={(props) => <Home {...props}></Home>}
                options={{ title: "David Fabreguette" }}
              ></Drawer.Screen>
              <Drawer.Screen
                name="CV"
                component={(props) => <CV {...props}></CV>}
                options={{ title: "David Fabreguette - CV" }}
              ></Drawer.Screen>
            </Drawer.Navigator>
          </NavigationContainer>
        </PaperProvider>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "red",
  },
});
