import React from "react";
import { View } from "react-native";
import { Appbar, useTheme, IconButton } from "react-native-paper";

export const AppBar = (props) => {
  console.log("props are", props);
  const { scene, previous, navigation } = props || {scene: {}};
  const theme = useTheme();
  const { options } = (scene || {}).descriptor || { options: {}};
  const title = "toto"
  
  return <View></View>
  return <Appbar.Header>
    {previous ? (
        <Appbar.BackAction
          onPress={navigation.goBack}
          color={theme.colors.secondary}
        />
      ) : (
        <IconButton 
          icon="menu"
          color={theme.colors.secondary}
          onPress={() => {
            // ((navigation as any) as DrawerNavigationProp<{}>).openDrawer();
            navigation.openDrawer();
          }}
        />
      )}


    <Appbar.Content title={title}></Appbar.Content>
  </Appbar.Header>
}